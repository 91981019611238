<template>
  <Header/>
  <div id="page-wrap">
    <main>
      <router-view></router-view>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
 
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700&family=Lora:ital@1&family=Poppins:wght@100;200;300;400;500;600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: content-box;
}
h1, h2, h3 {
  font-family: 'Almarai', sans-serif;
  font-weight: 300;
}
html, body, #app, #page-wrap {
  height: 100%;
}
main {
  min-height: 100%;
  position: relative;
}
body {
  width: 100%;
  margin: 0;
}
a {
  text-decoration: none;
}
p {
  font-size: 16px;
  line-height: 24px;
}
strong {
  font-weight: 600;
}
.bgc-hooker {
  background-color: #1C2C2B;
  color: white;
}
.bgc-brick-red {
  background-color: #502323;
  color: white;
}
button {
    align-self: center;
    padding: 10px 25px;
    border: 3px solid white;
    color: white;
    border-radius: 50px;
    background-color: transparent;
}
button  a {
    color: white;
    font-size: 150%;
    text-transform: uppercase;
}
.button-white {
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 30px;
}
.button-white::before {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: -250px;
    z-index: 0;
    transition: all 500ms ease-in;
}
.button-white:hover a{
    position: absolute;
    color: black;
    top: 25%;
    left: 13%;
    transition: all 500ms ease-in-out;
}
.button-white:hover::before{
    left: 0;
    transition: all 500ms ease-in-out;
}
.cross {
    position: relative;
    width: 30px;
    height: 30px;
}
.cross::before,
.cross::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    background-color: white;
    transform: rotate(45deg);
}
.cross::after {
    transform: rotate(135deg);
}
@media (max-width: 450px) {
  p {
    font-size: 11px;
    line-height: 16.5px;
  }
  button {
    padding: 5px 15px;
  }
  button a {
    font-size: 14px;
  }
  .button-white {
    width: 165px;
    height: 30px;
  }
}

</style>
