<template>
  <footer>
      <div class="mentions">
          <span>2023 © IRIS Digital</span>
          <span>Mentions légales</span>
      </div>
      <div class="links">
        <div class="links-socials">
          <a href="https://www.facebook.com/profile.php?id=100086455113900">
          <img src="../assets/img/fb.png" alt="lien facebook">
          </a>
          <a href="https://instagram.com/artisanatvan?igshid=ZDdkNTZiNTM=">
          <img src="../assets/img/insta.png" alt="lien instagram">
          </a>
        </div>
        <a href="mailto:artisanatvan@gmail.com">artisanatvan@gmail.com</a>
      </div>      
  </footer>
</template>

<script>
export default {
  name: 'Footer_comp'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  padding: 40px 150px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}
.mentions {
  display: flex;
  gap: 15px;
  align-items: center;
}
.mentions span {
  display: flex;
  align-items: center;
}
.mentions span:first-child:after {
  content: "";
  display: block;
  border-right: 1px black solid; 
  height: 30px;
  padding-right: 15px;
}
img {
  width: 20px;
}
a {
  color: black;
}
.links {
  display: flex;
  gap: 10px;
  align-items: center;
}
.links-socials {
  display: flex;
  align-items: center;
  border-right: 1px solid black;
  height: 30px;
  gap: 5px;
  padding-right: 10px;
}
@media (max-width: 550px) {
  footer {
    padding: 30px 50px;
    font-size: 9px;
  }
  .mentions {
    gap: 7px;
  }
  .mentions span:first-child::after {
    height: 12px;
    padding-right: 7px;
  }
  .links-socials {
    display: none;
  }
}

</style>