<template>
  <header id="header">
    <router-link to="/">
      <img id="header-img" src="../assets/img/Artisanat-logo.png" alt="logo Artisanat van">
    </router-link>
    <nav class="header-nav">
      <ul>
        <li class="desktop-elements">
          <router-link to="/team" class="header-links">l'équipe</router-link>
        </li>
        <li class="desktop-elements">
          <router-link to="/values" class="header-links">nos valeurs</router-link>
        </li>
        <li class="desktop-elements">
          <router-link to="/workshop" class="header-links">l'atelier</router-link>
        </li>
        <li class="desktop-elements">
          <router-link to="/layouts" class="header-links">aménagements</router-link>
        </li>
        <li class="desktop-elements">
          <router-link to="/gallery" class="header-links">galerie</router-link>
        </li>
        <li class="desktop-elements">
          <router-link to="/contact" class="header-links">contact</router-link>
        </li>
      </ul>
    </nav>
    <Slide right id="slide" closeOnNavigation="" >
      <nav class="header-mobile-nav">
        <ul>
          <li class="mobile-elements">
            <router-link to="/" class="header-links">Acceuil</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/team" class="header-links">l'équipe</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/values" class="header-links">nos valeurs</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/workshop" class="header-links">l'atelier</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/layouts" class="header-links">aménagements</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/gallery" class="header-links">galerie</router-link>
          </li>
          <li class="mobile-elements">
            <router-link to="/contact" class="header-links">contact</router-link>
          </li>
        </ul>
      </nav>
    </Slide>
  </header>
</template>

<script>
import { Slide } from 'vue3-burger-menu'

export default {
  name: 'Header_comp',
  components : {
    Slide
  },
  data() {
    return {
      visible: true,
      scrollPos: 0      
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$route' (to) {
      let header = document.getElementById('header')
      let img = document.getElementById('header-img')
      let links = document.getElementsByClassName('header-links')
      let desktopElements = document.getElementsByClassName('desktop-elements')
      let mobileElements = document.getElementsByClassName('mobile-elements')
      let burgerBars = document.getElementsByClassName('bm-burger-bars')
      let crossBars = document.getElementsByClassName('bm-cross')
      let menuBurger = document.getElementsByClassName('bm-menu')[0]
      
      if(to.name==="ContactPage" || to.name==="HomePage" ) {
        if(to.name==="HomePage") {
          header.classList.add("hidden-header");
        }
        header.classList.remove("bgc-white");
        menuBurger.style.backgroundColor ="rgb(28, 44, 43)"
        img.style.filter = "none";
        for(let i=0; i<links.length; i++) {
          links[i].style.color = "white"
          if (i<3) {
            burgerBars[i].style.backgroundColor =" white"
            if (i<2) {
              crossBars[i].style.backgroundColor =" white"
            }
          }
        }
        for(let i=0; i<desktopElements.length; i++) {
          if (i<desktopElements.length-1 ) {
            desktopElements[i].style.borderRight = "1px white solid"
          }
          mobileElements[i].style.borderBottom = "1px white solid"
        }
      }else {
        header.classList.add("bgc-white");
        menuBurger.style.backgroundColor =" white"
        img.style.filter = "invert()";
        for(let i=0; i<links.length; i++) {
          links[i].style.color = "black"
          if (i<3) {
            burgerBars[i].style.backgroundColor = "black"
            if (i<2) {
              crossBars[i].style.backgroundColor =" black"
            }
          }
        }
        for(let i=0 ; i<desktopElements.length; i++) {
          if (i<desktopElements.length-1 ) {
            desktopElements[i].style.borderRight = "1px black solid"
          }
          mobileElements[i].style.borderBottom = "1px black solid"
        }
      }
    }
  },
  methods: {
    handleScroll() {
      let header = document.getElementById('header')
      let oldScrollPos = this.scrollPos
      this.scrollPos = window.pageYOffset;

      if( this.$route.name==="HomePage") {
        if (window.innerWidth > 450) {
          if (this.scrollPos < 950) {
            header.classList.add("hidden-header");
          }else if (950 < this.scrollPos && this.scrollPos< 1100){ 
            header.classList.remove("hidden-header")
          }else {
            if (this.scrollPos > oldScrollPos) {
              header.classList.add("hidden-header");
            }
            if (this.scrollPos < oldScrollPos) {
              header.classList.remove("hidden-header");
            } 
          }        
        }else {
          if (this.scrollPos < 720) {
            header.classList.add("hidden-header");
          }else if (700 < this.scrollPos && this.scrollPos< 850){ 
            header.classList.remove("hidden-header")
          }else {
            if (this.scrollPos > oldScrollPos) {
              header.classList.add("hidden-header");
            }
            if (this.scrollPos < oldScrollPos) {
              header.classList.remove("hidden-header");
            } 
          }
        }
      }else {
        if (this.scrollPos > 50) {
          if (this.scrollPos > oldScrollPos) {
            header.classList.add("hidden-header");
          }
          if (this.scrollPos < oldScrollPos) {
            header.classList.remove("hidden-header");
          } 
        }
      }
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  background-color: rgba(28, 44, 43, 0.92);
  padding: 15px 6%; 
  display: flex;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  height: 80px;
  opacity: 1;
  transition: all 500ms ease-in;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.bgc-white {
  background-color: #fff;
}
.hidden-header {
  height: 0px;
  opacity: 0;
  transition: all 500ms ease-in;
}
header > a {
  padding: 2px 0 ;
}
header img {
  width: 60px;
  height: auto;
  margin: auto 0;
}
ul{
  display: flex;
  list-style: none;
  align-items: center;
}
nav a {
  font-size: 16px;
  color: white;
  text-transform: uppercase;
}
.header-nav a:after {
  content: "";
  border-bottom: 1px white solid;
  display: block;
  opacity: 0;
}
.header-nav a:hover:after {
  opacity: 1;
  transition: all 500ms;
}
.header-nav li:not(:last-child) {
  border-right: 1px white solid;
}
li {
  padding:0 30px;
  height: 25px;
}
#slide{
  display: none;
}
@media (max-width: 1100px) {
  header {
    padding: 15px 10%;
  }
  .header-nav {
    display: none;
  }
  #slide{
    display: contents;
  }
  :deep .bm-burger-button {
    left: 80%;
    top: 30px;
    width: 25px;
    height: 20px;
  }
  :deep .line-style {
    height: 8%;
  }
  :deep .bm-burger-bars {
    background-color: #fff;
  }
  :deep .bm-menu {
    background-color: rgb(28, 44, 43);
  }
  :deep .bm-item-list, .header-mobile-nav {
    height: 75%;
  }
  .header-mobile-nav ul {
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px
  }
  .header-mobile-nav li {
    padding: 0;
    width: 150px;
    text-align: right;
  }
  .header-mobile-nav li:not(:last-child) {
    border-bottom: 1px white solid;
    padding-bottom: 20px;
  }
  :deep .bm-cross-button {
    height: 50px;
    width: 50px;
  }
  :deep .cross-style {
    top: 30px;
    right: 30px;
  }
  :deep .bm-cross {
    width: 2px !important;
    height: 20px !important;
    background-color: white;
  }
}

</style>
