import { createRouter, createWebHistory } from "vue-router"
import HomePage  from '@/views/HomePage'
import TeamPage  from '@/views/TeamPage'
import ValuesPage from '@/views/ValuesPage'
import WorkshopPage from '@/views/WorkshopPage'
import LayoutsPage from '@/views/LayoutsPage'
import GalleryPage from '@/views/GalleryPage'
import ContactPage  from '@/views/ContactPage'
import NotFoundPage  from '@/views/NotFoundPage'

const router = createRouter({
  scrollBehavior() {
     return { top: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: { name: 'HomePage' }
    },
    {
      name: "HomePage",
      path: '/',
      component: HomePage
    },
    { 
      name:'TeamPage',
      path: '/team',
      component: TeamPage 
    },
    { 
      name:'ValuesPage',
      path: '/values',
      component: ValuesPage 
    },
    
    { 
      name:'WorkshopPage',
      path: '/workshop',
      component: WorkshopPage
    },
    { 
      name:'LayoutsPage',
      path: '/layouts',
      component: LayoutsPage
    },
    { 
      name:'GalleryPage',
      path: '/gallery',
      component: GalleryPage
    },
    {
      name:'ContactPage',
      path: '/contact',
      component: ContactPage 
    },
    {
      name: 'NotFound',
      path: '/:pathMatch(.*)',     
      component: NotFoundPage
    }
  ]  
})


export default router
